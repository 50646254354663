export const ROUTES = {
  about: '/about-us',
  contact: '/contact-us',
  services: '/services',
  trackIt: '/track-it',

  admin: {
    index: '/admin',
    login: '/admin/login',
    add_tracking: '/admin/tracking',
    settings: '/admin/settings',
  },
}

export const SERVICES = [
  'Air Freight',
  'Ocean Freight',
  'Cargo Transportation',
  'Packaging and Storage',
]

export const TRACKING_STATUS = {
  Active: 'Active',
  Inactive: 'Inactive',
  'Picked Up': 'Picked Up',
  Arrived: 'Arrived',
  Delivered: 'Delivered',
  'On hold': 'On hold',
}

export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://web-production-44d1d.up.railway.app'
    : 'http://localhost:4000'
