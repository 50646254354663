import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import UnbeatableSection from '../../Components/UnbeatableSection'
import OurServiceSection from '../../Components/OurServiceSection'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Input from '../../Components/Input'
import InputArea from '../../Components/InputArea'
import CustomButton from '../../Components/CustomButton'
import SlideShow from '../../Components/SlideShow'

import slide1 from '../../Assets/Images/slide_1.jpeg'
import slide2 from '../../Assets/Images/slide_2.jpeg'
import slide3 from '../../Assets/Images/slide_3.jpeg'
import AccordionGroup from '../../Components/AccordionGroup'
import { SERVICES } from '../../constants'
import { useForm } from '../../Hooks/useForm'
import emailjs from '@emailjs/browser'

const templateId = 'template_tqty2mv'
const serviceId = 'service_pfpb65h'
const publicId = 's6HNn9FJmskIhvYFR'

const accordions = [
  {
    icon: <FontAwesomeIcon className="icon" icon={solid('circle-info')} />,
  },
  {
    icon: <FontAwesomeIcon className="icon" icon={solid('truck')} />,
  },
  {
    icon: <FontAwesomeIcon className="icon" icon={solid('plane-up')} />,
  },
]

const AIR_FREIGHT = [
  {
    title: 'Air Chater',
    content:
      'We take on those challenges others might consider impossible. We take maximum care of all requests which are outside the “usual” airfreight parameters.',
  },
  {
    title: 'On Board Courier',
    content:
      'With the maximum of care, dedication and speed, even when all traditional solutions are not available, our team will find the perfect solution for urgent shipment.',
  },
  {
    title: 'Air Premium',
    content:
      'Air Premium is the right service for those shipments that have a tight due date schedule and need a time-definite delivery at final destination - worldwide.',
  },
]

const OCEAN_FREIGHT = [
  {
    title: 'Tailored Solutions',
    content:
      'Meeting customers’ unique needs with premium service and local expertise. Flexible options, exception management, frequent sailings, and on-time delivery.',
  },
  {
    title: 'Large Global Footprint',
    content:
      'Agility-owned offices throughout the world – including high-growth emerging markets – guarantee we can support our customers, wherever you go.',
  },
  {
    title: 'Cargo Visibilty and Management',
    content:
      'Market-leading Track & Trace capabilities, online logistics management, powerful reports and analytics, and CO2 reporting.',
  },
]

const PACKAGING_AND_STORAGE = [
  {
    title: 'Food Grade Warehouse',
    content:
      'We understand the issues facing storage, shipping, and handling of inventory in a food grade warehouse. It can be a pain, we are here to help.',
  },
  {
    title: 'Distribution Center',
    content:
      'Effective distribution requires real-time, ongoing strategy. Not only do we focus on exceeding your distribution center needs – we also use industry insights and technology to predict them.',
  },
  {
    title: 'Dedicated Warehouse',
    content:
      'When you need warehousing space and a dedicated team, get it with Commercial Warehousing.',
  },
]

const Service = () => {
  const { state } = useLocation()

  const [activeService, setActiveService] = useState(SERVICES[0])

  useEffect(() => {
    if (state?.service) {
      setActiveService(state.service)
    }
  }, [state])

  const handleServiceClick = (index) => () => {
    setActiveService(SERVICES[index])
  }

  const getServiceTitle = () => {
    switch (activeService) {
      case SERVICES[0]:
        return 'Our global network has the power to help businesses grow – based on years of experience and influenced by the changing needs of our customers.'

      case SERVICES[1]:
        return 'Our wide experience in sea freight transport allow us to offer quality services for your imports and exports and process the documentation through our customs agents'

      case SERVICES[2]:
        return 'ZHL Logistics Cargo handles cargo and mail from 08:00 until 20:00, performs express customs clearance of exported and imported goods in accordance with IATA rules, which allows to operate in the international transport system.'

      case SERVICES[3]:
        return 'ZHL Logistics offers ambient, air-conditioned and open yard storage solutions to meet your every need'

      default:
        return ''
    }
  }

  const getServiceContent = () => {
    switch (activeService) {
      case SERVICES[0]:
        return `Airfreight is fast-moving, challenging and constantly changing – it’s the nature of the business. Around the globe capacity and demand can change in the blink of an eye so it’s essential to have a partner who provides stability and reliability to maintain your supply chain’s total integrity. Whether your shipments fly in the bellies of commercial planes or require specialist freighter aircraft to move them,ZHL Logistics can provide tailor-made solutions for your all your airfreight.\n\nZHL Logistics is one of the largest air freight transportation providers in the world, offering a full and comprehensive package of premium, value-added services and specialist products all of which are specifically tailor-made for you. Across our worldwide network you can choose the service which best suits your needs. From Alaska to Zanzibar and everywhere in between, your localZHL Logistics airfreight expert is just a click or a call away.\n\nAnd, regardless of the size of your business, our dedicated implementation teams will ensure a smooth and quick introduction to our systems while withZHL Logistics Track you will always be up-to-date on the current status of your shipments.\nCall or email us today to request a call from one of our sales colleagues to create new opportunities for your air freight supply chain and benefit from our vast experience in air freight forwarding.`

      case SERVICES[1]:
        return `Whether container load or special transport: As a reliable partner for your sea freight transports, we handle all orders competently, carefully and fast via every desired port worldwide. Gebrüder Weiss charters fixed cargo space on important sea freight routes in order to safeguard the hold capacity.\n\nAll transports are completely monitored with state-of-the-art IT systems. In combination with unified quality standards, this guarantees our customers a high level of transparency and planning security.\n\nIn addition, we provide you with information about all the framework conditions such as customs regulations or transport insurances, and support you in obtaining all the required import and export documents. The entire handling process of your transport is thus controlled by Gebrüder Weiss expertly and reliably.\n\nWe are happy to answer any questions you might have regarding the supplied destinations, the possible freight options, the duration of the transport to its final destination or the incurred costs.`

      case SERVICES[2]:
        return `The Cargo Terminal capacity allows to handle special cargo. The warehouses are equipped with refrigerating and freeze chambers to accommodate perishable cargo.\n\nThe Cargo Terminal also provides handling of heavy / oversized cargo with large tonnage lifts. Oversized cargo may be stored on designated areas.\n\n Sochi International Airport also acts as an agent/subagent of major air carriers like S7, Russia, UTair and many others, which allows the cargo shipper to opt for the most suitable air carrier, route, and fare.\n\nUpon a cargo shipper's request, the staff of the Cargo Terminal will furnish the required information about all destinations of concern and will be ready to establish long-term contractual relationships.\n\nThe Cargo Terminal offers competitive fares for cargo operation thanks to the absence of intermediaries.`

      case SERVICES[3]:
        return `Falcon’s industry-leading facilities offer extensive capacity and a range of storage solutions to meet all your warehousing and open yard storage needs. Strategically located in the industrial heartland of the Emirate, Agility’s open yard and ambient or air conditioned warehouse facilities in Mussafah port and KIZAD provide easy access to Abu Dhabi’s logistics network and the Emirate’s commercial and retail centers.\n\nOur warehousing service is in the midst of a tech-driven revolution as companies race to identify and adopt emerging technologies that cut costs, optimize operations and improve overall supply chain efficiency. In some of the largest economies and companies, warehouse drones and robots already have been employed.`

      default:
        return ''
    }
  }

  const getAccordionData = () => {
    switch (activeService) {
      case SERVICES[0]:
        return accordions.map((acc, idx) => {
          acc.title = AIR_FREIGHT[idx].title
          acc.content = AIR_FREIGHT[idx].content

          return acc
        })

      case SERVICES[1]:
      case SERVICES[2]:
        return accordions.map((acc, idx) => {
          acc.title = OCEAN_FREIGHT[idx].title
          acc.content = OCEAN_FREIGHT[idx].content

          return acc
        })

      case SERVICES[3]:
        return accordions.map((acc, idx) => {
          acc.title = PACKAGING_AND_STORAGE[idx].title
          acc.content = PACKAGING_AND_STORAGE[idx].content

          return acc
        })

      default:
        return []
    }
  }

  const { form, reset, onChange } = useForm({
    name: '',
    address: '',
    email: '',
    msg: '',
    loading: false,
  })

  const handleChange = (e) => onChange(e.target.name, e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    onChange('loading', true)

    try {
      await emailjs.send(serviceId, templateId, form, publicId)

      reset()
    } catch (error) {
      console.log('ERROR', error)
    }

    onChange('loading', false)
  }

  return (
    <div className="service">
      <div className="service__top">
        <h1>{activeService}</h1>
      </div>

      <div className="service__content">
        <section className="service-content">
          <div className="main-content">
            <SlideShow slideImages={[slide1, slide2, slide3]} />

            <h2 className="main-content__title">{getServiceTitle()}</h2>

            <div className="main-content__content">{getServiceContent()}</div>

            <h4>Our services: choose the right fit for your specific needs</h4>

            <div className="accordion-container">
              <AccordionGroup data={getAccordionData()} />
            </div>
          </div>

          <div className="side-menu">
            <div className="services-list">
              <ul>
                <li
                  className={
                    activeService === SERVICES[0] ? 'active' : undefined
                  }
                  onClick={handleServiceClick(0)}
                >
                  <div>Air Freight</div>
                </li>

                <li
                  className={
                    activeService === SERVICES[1] ? 'active' : undefined
                  }
                  onClick={handleServiceClick(1)}
                >
                  <div>Ocean Freight</div>
                </li>

                <li
                  className={
                    activeService === SERVICES[2] ? 'active' : undefined
                  }
                  onClick={handleServiceClick(2)}
                >
                  <div>Cargo Transportation</div>
                </li>

                <li
                  className={
                    activeService === SERVICES[3] ? 'active' : undefined
                  }
                  onClick={handleServiceClick(3)}
                >
                  <div>Packaging and Storage</div>
                </li>
              </ul>
            </div>

            <div className="contact-widget">
              <div className="location">
                <h3>LOCATION</h3>

                <div className="location__content">
                  <FontAwesomeIcon
                    className="icon"
                    icon={solid('location-dot')}
                  />

                  <div>
                    <span>Location:</span>

                    <span>GLOBAL FACILITY OFFICE</span>
                  </div>
                </div>
              </div>

              <div className="call-center">
                <h3>CALL CENTER</h3>

                <div className="call-center__content">
                  <FontAwesomeIcon className="icon" icon={solid('phone')} />

                  <div>
                    <span>Call Us:</span>

                    <span>+1 (929) 564-3902</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="contact-form">
              <h3>Get A Free Quote</h3>

              <form onSubmit={handleSubmit}>
                <Input
                  title="Full Name"
                  required
                  placeholder="Name"
                  value={form.name}
                  onChange={handleChange}
                  name="name"
                />

                <Input
                  title="Email"
                  type="email"
                  required
                  placeholder="Email"
                  value={form.email}
                  onChange={handleChange}
                  name="email"
                />

                <InputArea
                  title="Message"
                  required
                  rows={3}
                  value={form.msg}
                  onChange={handleChange}
                  name="msg"
                />

                <CustomButton
                  otherClassName="submit"
                  loading={form.loading}
                  label="SEND MESSAGE"
                />
              </form>
            </div>
          </div>
        </section>

        <UnbeatableSection />

        <OurServiceSection />
      </div>
    </div>
  )
}

export default Service
